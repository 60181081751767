export enum TextMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum HeaderStyle {
  CIRCULAR = 'circular',
  FLAT = 'flat',
}

export enum Alignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ColorType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum LoaderStyle {
  LEFT_GRAY = 'left_gray',
  CENTER_PRIMARY = 'center_primary',
}

export enum RatingStyle {
  OUTLINE_GRAY = 'o_gray',
  OUTLINE_GREEN_RED = 'o_green_red',
}

export enum ChatBubbleStyle {
  BLOCK = 'block',
  ROUNDED = 'rounded',
}

export interface WidgetColors {
  primaryColor?: string
  secondaryColor?: string
  headerCircleColor?: string
  headerGradientHighlight?: string
}

export interface WidgetLayout {
  zIndexToggle?: number
  zIndexModal?: number
  borderRadius?: number
  submitBorderWidth?: number
  headerTitleIndent?: boolean
  headerAssetWidth?: number
  headerAssetTopOffset?: number
  headerAssetLeftOffset?: number
  chatBubbleStyle?: ChatBubbleStyle
  headerStyle?: HeaderStyle
  loaderStyle?: LoaderStyle
  ratingStyle?: RatingStyle
  chatToggleCircleBackground?: boolean | 'primary' | 'secondary' | string
}

export interface WidgetApiPaths {
  sendMessage?: string
  health?: string
  healthOpenAi?: string
  memory?: string
}

export interface WidgetContent {
  aiName?: string
  modalTitle?: string
  chattingWith?: string
  introduction?: string
  inputPlaceholder?: string
  rateMessage?: string
  rateMessageResponsePositive?: string
  rateMessageResponseNegative?: string
  footnote?: string
}

export interface WidgetAssetUrls {
  chatToggle?: string
  chatHeader?: string
  agentResponse?: string
}

export interface ApiConfig {
  endpoint: string
  source: string
  apikey: string
}

export interface WidgetProps {
  /** Required prop */
  api?: ApiConfig
  /** Text color mode to match primary color */
  textMode?: TextMode
  /** Complex configuration groups */
  apiPaths?: WidgetApiPaths
  colors?: WidgetColors
  layout?: WidgetLayout
  content?: WidgetContent
  assetUrls?: WidgetAssetUrls
}

export interface WidgetPropsBackwardCompatible extends WidgetProps {
  source?: string
  apikey?: string
  intro?: string
  primaryColor?: string
  secondaryColor?: string
}
