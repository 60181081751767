<script setup lang="ts">
import { withDefaults, ref, watch, computed, onMounted } from 'vue'

import {
  WidgetPropsBackwardCompatible,
  WidgetContent,
  WidgetAssetUrls,
  WidgetLayout,
  TextMode,
  WidgetApiPaths,
} from './Widget.types.ts'
import { defaultApiPaths, defaultAssetUrls, defaultContent, defaultLayout } from './Widget.defaults.ts'

import Modal, { ModalProps } from './components/Modal.vue'
import ButtonControl from './components/ButtonControl.vue'

import { useUser } from './composables/useUser'
import { useSendMessage } from './composables/useSendMessage'
import { useIsChatOpen } from './composables/useIsChatOpen'
import { useMessages } from './composables/useMessages'
import { useScrollToLast } from './composables/useScrollToLast'

import { getApiConfig, setCssVariables, readStream } from './utils'

import './Widget.scss'

const props = withDefaults(defineProps<WidgetPropsBackwardCompatible>(), {
  textMode: TextMode.DARK,
  apiPaths: () => defaultApiPaths,
  assetUrls: () => defaultAssetUrls,
  content: () => defaultContent,
  layout: () => defaultLayout,
  colors: () => ({
    primaryColor: '',
    secondaryColor: '',
    headerCircleColor: '',
    headerGradientHighlight: '',
  }),
  /** Backwards compatibility */
  source: '',
  apikey: '',
  intro: '',
  primaryColor: '',
  secondaryColor: '',
})

// Interpolate partial config with defaults
const configLayout: WidgetLayout = { ...defaultLayout, ...props.layout }
const configContent: WidgetContent = { ...defaultContent, ...props.content }
const configAssetUrls: WidgetAssetUrls = { ...defaultAssetUrls, ...props.assetUrls }
const configApiPaths: WidgetApiPaths = { ...defaultApiPaths, ...props.apiPaths }

// Backwards compatibility
if (props.intro) {
  configContent.introduction = props.intro
}

const useSecondaryColor = !!(props.colors.secondaryColor || props.secondaryColor)

const { apikey, source, endpoint } = getApiConfig(props)

const modalRef = ref<{
  chatContainer: HTMLElement | null
}>({
  chatContainer: null,
})

const { scrollToLast } = useScrollToLast(modalRef)

const { firstConversation, setUserUUID, getUserUUID, deleteUserUUID, refreshMemory, refreshChatMemory } = useUser({
  endpoint,
  memoryPath: configApiPaths.memory,
  scrollToLast,
})

const { isChatOpen } = useIsChatOpen()

const showChatContainerImmediately = ref(false)

const isStreaming = ref(false)
const userUuid = ref('')
const ratingIndex = ref([{ index: 0, rating: 0 }])

const { messages, publishMessage } = useMessages(scrollToLast)
const message = ref('')

const {
  request: sendMessageRequest,
  isReady,
  isLoading,
  checkHealth,
} = useSendMessage(endpoint, apikey, messages, {
  healthPath: configApiPaths.health,
  healthOpenAiPath: configApiPaths.healthOpenAi,
})

const modalProps = computed(() => {
  return {
    // General Config
    textMode: props.textMode,
    // Content Config
    aiName: configContent.aiName,
    modalTitle: configContent.modalTitle,
    introduction: configContent.introduction,
    chattingWith: configContent.chattingWith,
    inputPlaceholder: configContent.inputPlaceholder,
    rateMessage: configContent.rateMessage,
    footnote: configContent.footnote,
    // Layout Config
    zIndex: configLayout.zIndexModal,
    headerStyle: configLayout.headerStyle,
    headerAssetUrl: configAssetUrls.chatHeader,
    headerTitleIndent: configLayout.headerTitleIndent,
    headerAssetWidth: configLayout.headerAssetWidth,
    headerAssetTopOffset: configLayout.headerAssetTopOffset,
    headerAssetLeftOffset: configLayout.headerAssetLeftOffset,
    agentResponseUrl: configAssetUrls.agentResponse,
    borderRadius: configLayout.borderRadius,
    submitBorderWidth: configLayout.submitBorderWidth,
    chatBubbleStyle: configLayout.chatBubbleStyle,
    loaderStyle: configLayout.loaderStyle,
    // State Data
    firstConversation: firstConversation.value,
    showChatContainerImmediately: showChatContainerImmediately.value,
    isChatOpen: isChatOpen.value,
    ratingIndex: ratingIndex.value,
    isStreaming: isStreaming.value,
    isLoading: isLoading.value,
    useSecondaryColor,
    toggleChat,
  } as ModalProps
})

const appendToLastMessage = (messageChunk: string) => {
  const messagesIdx = messages.value.length - 1
  const updatedMessage = messages.value[messagesIdx].content + messageChunk

  messages.value[messagesIdx] = {
    role: 'assistant',
    content: updatedMessage,
  }
}

const sendMessage = async (ev: Event) => {
  ev.preventDefault()
  if (message.value === '' || isLoading.value || isStreaming.value) {
    return
  }

  const prompt = message.value.trim()
  message.value = ''
  publishMessage(prompt)

  const response = await sendMessageRequest(configApiPaths.sendMessage, {
    userUuid: userUuid.value,
    inputMessage: prompt,
    sourceId: source,
  })

  if (!response?.body) {
    return
  }

  isStreaming.value = true

  await readStream(response.body).onReadChunk({
    processChunk: appendToLastMessage,
    onIteration: scrollToLast,
  })

  isStreaming.value = false
}

function resetHistory() {
  deleteUserUUID()
  setUserUUID()
  userUuid.value = getUserUUID()
  messages.value = []
  showChatContainerImmediately.value = false
}

function toggleChat(bool: boolean | null) {
  isChatOpen.value = bool ?? !isChatOpen.value
  scrollToLast()
}

function updateRating({ rating, content, index }: { rating: any; content: any; index: number }) {
  fetch(`${endpoint}${configApiPaths.memory}/${getUserUUID()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      rating,
      content,
    }),
  }).catch(() => {})

  const existingIndex = ratingIndex.value.findIndex(rIndex => rIndex.index === index)

  if (existingIndex !== -1) {
    // If the index exists, update its rating
    const updatedRatingIndex = [...ratingIndex.value]
    updatedRatingIndex[existingIndex] = {
      ...updatedRatingIndex[existingIndex],
      rating: rating,
    }
    ratingIndex.value = updatedRatingIndex
  } else {
    // If the index doesn't exist, add a new entry
    ratingIndex.value = [...ratingIndex.value, { index: index, rating: rating }]
  }

  if (rating && configContent.rateMessageResponsePositive) {
    publishMessage(configContent.rateMessageResponsePositive, 'assistant-rating')
  }

  if (!rating && configContent.rateMessageResponseNegative) {
    publishMessage(configContent.rateMessageResponseNegative, 'assistant-rating')
  }
}

/**
 * Chat container remains hidden until intro fades out
 *  however if intro doesn't display (ie. prior history)
 *  then we show chat box instantly
 */
watch(
  () => isChatOpen.value,
  (isOpen, wasOpen) => {
    if (!wasOpen && isOpen && messages.value.length) {
      showChatContainerImmediately.value = true
    }
  }
)

watch(
  () => refreshChatMemory.value,
  async () => {
    try {
      await refreshMemory()
    } catch (error) {
      console.error(error)
    }
  }
)

watch(
  () => userUuid.value,
  async () => {
    setUserUUID()
    userUuid.value = getUserUUID() as string

    if (userUuid.value) {
      try {
        await refreshMemory()
      } catch (error) {
        console.error(error)
      }
    }
  },
  { immediate: true }
)

onMounted(() => {
  checkHealth()
  setCssVariables(props)
})

/**
 * External modal control via ref
 *  for component implementation
 */
defineExpose({ isChatOpen })
</script>

<template>
  <Transition>
    <div v-if="userUuid && isReady">
      <ButtonControl
        :is-chat-open="isChatOpen"
        :z-index="configLayout.zIndexToggle"
        :first-conversation="firstConversation"
        :text-mode="textMode"
        :asset-url="configAssetUrls.chatToggle"
        :chat-toggle-circle-background="configLayout.chatToggleCircleBackground"
        @click="toggleChat"
      />

      <Modal
        ref="modalRef"
        v-model="message"
        v-bind="modalProps"
        @reset-history="resetHistory"
        @update-rating="updateRating"
        @submit="sendMessage"
      />
    </div>
  </Transition>
</template>
