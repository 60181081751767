<script setup lang="ts">
import { ref, computed } from 'vue'

import ContainerModal from './Container/Modal.vue'
import ContainerChat from './Container/Chat.vue'

import Header from './Header.vue'
import Footer from './Footer.vue'
import UserInput from './UserInput.vue'

import MessageAssistant from './Message/Assistant.vue'
import MessageSystem from './Message/System.vue'
import MessageUser from './Message/User.vue'

import { useShortHeader } from '../composables/useShortHeader'
import { ChatBubbleStyle, HeaderStyle, LoaderStyle, TextMode } from '../Widget.types'
import { useMessages } from '../composables/useMessages'

export interface ModalProps {
  inputPlaceholder: string
  aiName: string
  zIndex?: number
  modalTitle: string
  chattingWith: string
  introduction: string
  rateMessage: string
  footnote: string
  headerStyle: HeaderStyle
  headerAssetUrl: string | undefined
  headerTitleIndent: boolean
  headerAssetWidth: number
  headerAssetTopOffset: number
  headerAssetLeftOffset: number
  agentResponseUrl: string
  borderRadius: number
  submitBorderWidth: number
  loaderStyle: LoaderStyle
  chatBubbleStyle: ChatBubbleStyle
  textMode: TextMode
  firstConversation: boolean
  isChatOpen: boolean
  ratingIndex: any[]
  isStreaming: boolean
  showChatContainerImmediately: boolean
  useSecondaryColor: boolean
  isLoading: boolean
  toggleChat: (bool: boolean | null) => void
}

const props = defineProps<ModalProps>()

const { messages } = useMessages()

const message = defineModel()
const emit = defineEmits(['submit', 'resetHistory', 'updateRating'])

const showChatContainer = ref(false)
const modalRef = ref({ chatContainer: null })
const chatContainer = computed(() => modalRef.value.chatContainer)

const hasMessageHistory = computed(() => !!messages.value.length)
const { supportsLongHeader, shortHeaderActive } = useShortHeader(props.aiName, props.headerAssetUrl, hasMessageHistory)

const isCircular = computed(() => {
  return props.headerStyle === HeaderStyle.CIRCULAR
})

const setShowChatContainer = (bool: boolean) => (showChatContainer.value = bool)

defineExpose({ chatContainer })
</script>

<template>
  <ContainerModal :is-chat-open="isChatOpen" :border-radius="borderRadius" :z-index="zIndex">
    <Header
      :is-chat-open="isChatOpen"
      :is-loading="isLoading"
      :is-streaming="isStreaming"
      :header-style="headerStyle"
      :border-radius="borderRadius"
      :ai-name="aiName"
      :modal-title="modalTitle"
      :chatting-with="chattingWith"
      :supports-long-header="supportsLongHeader"
      :shrink-header="shortHeaderActive"
      :text-mode="textMode"
      :toggle-chat="toggleChat"
      :has-history="hasMessageHistory"
      :header-asset-url="headerAssetUrl"
      :header-title-indent="headerTitleIndent"
      :header-asset-width="headerAssetWidth"
      :header-asset-top-offset="headerAssetTopOffset"
      :header-asset-left-offset="headerAssetLeftOffset"
      @reset-history="emit('resetHistory')"
    />

    <!-- Introduction -->
    <Transition @before-enter="setShowChatContainer(false)" @after-leave="setShowChatContainer(true)">
      <div
        v-if="isChatOpen && !hasMessageHistory"
        class="rw-introduction rw-absolute rw-top-[50%] rw-z-[50] -rw-translate-y-1/2 rw-space-y-2 rw-pe-8 rw-ps-4 rw-text-lg rw-leading-7 md-h:rw-top-[60%]"
        v-dompurify-html="introduction"
      ></div>
    </Transition>

    <!-- Messages -->
    <ContainerChat
      ref="modalRef"
      :is-chat-open="isChatOpen"
      :supports-long-header="supportsLongHeader"
      :shrink-header="shortHeaderActive"
      :visible="isChatOpen && hasMessageHistory"
    >
      <Transition>
        <div>
          <div
            v-if="showChatContainerImmediately || (showChatContainer && hasMessageHistory)"
            v-for="(message, index) in messages"
            :key="`${String(message.content)}${index}`"
          >
            <MessageAssistant
              :index="index"
              :message="message"
              :rate-message="rateMessage"
              :is-circular="isCircular"
              :is-loading="isLoading"
              :total-messages="messages.length"
              :text-mode="textMode"
              :rating-index="ratingIndex"
              :is-streaming="isStreaming"
              :loader-style="loaderStyle"
              :chat-bubble-style="chatBubbleStyle"
              :agent-response-url="agentResponseUrl"
              @update-rating="emit('updateRating', $event)"
            />
            <MessageUser :message="message" :text-mode="textMode" :chat-bubble-style="chatBubbleStyle" />
            <MessageSystem :message="message" :text-mode="textMode" :chat-bubble-style="chatBubbleStyle" />
          </div>
        </div>
      </Transition>
    </ContainerChat>

    <UserInput
      v-model="message"
      :placeholder="inputPlaceholder"
      :is-chat-open="isChatOpen"
      :text-mode="textMode"
      :submit-border-width="submitBorderWidth"
      :use-secondary-color="useSecondaryColor"
      @submit="emit('submit', $event)"
    />

    <Footer :is-chat-open="isChatOpen" :border-radius="borderRadius" :footnote="footnote" />
  </ContainerModal>
</template>

<style lang="scss" scoped>
.rw-introduction :deep() {
  p {
    @apply rw-leading-7;
  }
}
</style>
