<script setup lang="ts">
import { computed } from 'vue'

import { TextMode } from '../Widget.types'
import DefaultChatBubble from './DefaultChatBubble.vue'

const props = defineProps<{
  isChatOpen: boolean
  zIndex?: number
  firstConversation: boolean
  textMode: string
  assetUrl: string | undefined
  chatToggleCircleBackground?: boolean | 'primary' | 'secondary' | string
}>()

const bounce = computed(() => {
  return props.firstConversation && !props.isChatOpen
})

const usingDefaultSvg = computed(() => !props.assetUrl)

const useChatToggleBackground = computed(() => {
  return usingDefaultSvg.value || props.chatToggleCircleBackground
})

const buttonBackgroundClass = computed(() => {
  const bg = props.chatToggleCircleBackground
  if (bg === true || bg === 'primary' || usingDefaultSvg.value) {
    return 'rw-bg-primaryColor'
  }
  if (bg === 'secondary') {
    return 'rw-bg-secondaryColor'
  }
  return ''
})

const buttonBackgroundHex = computed(() => {
  const bg = String(props.chatToggleCircleBackground)
  const hexRegex = new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$')
  return hexRegex.test(bg) ? bg : ''
})

const emit = defineEmits(['click'])
</script>

<template>
  <Transition>
    <button
      v-if="!isChatOpen"
      id="revium-chatbot-open"
      class="rw-fixed rw-bottom-5 rw-right-4 rw-z-[200] rw-flex rw-h-16 rw-w-16 rw-origin-center rw-cursor-pointer rw-items-center lg:rw-h-24 lg:rw-w-24"
      :class="[
        bounce && 'rw-animate-bounce-with-shadow',
        useChatToggleBackground && 'rw-rounded-full rw-p-4 lg:rw-p-6',
        buttonBackgroundClass,
      ]"
      :style="{
        ...(buttonBackgroundHex && { backgroundColor: buttonBackgroundHex }),
        ...(zIndex && { zIndex }),
      }"
      @click="
        () => {
          // Transition DOM removal takes a moment after
          //  full fade opacity, so check for open state
          //  to avoid double click (open>close)
          !isChatOpen && $emit('click')
        }
      "
    >
      <div
        class="rw-transition-out-quad rw-relative rw-flex rw-w-full rw-justify-center rw-text-lg rw-transition-transform"
        :class="[textMode === TextMode.LIGHT ? 'rw-text-white' : 'rw-text-black']"
      >
        <DefaultChatBubble class="rw-h-full rw-w-full" v-if="usingDefaultSvg" />
        <img v-else :src="assetUrl" width="100" title="Open chat" />
      </div>
    </button>
  </Transition>
</template>

<style lang="scss" scoped>
.rw-animate-bounce-with-shadow {
  animation: shadow-bounce 2s infinite;

  /** Perspective shadow */
  @keyframes shadow-bounce {
    0%,
    100% {
      transform: translateY(-25%);
      filter: drop-shadow(8px 20px 6px rgba(0, 0, 0, 0.12));
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      filter: drop-shadow(2px 8px 4px rgba(0, 0, 0, 0.25));
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
</style>
